import React, { useEffect } from 'react';

import { Link } from 'site-react/components/navigation';
import { Curve, Section } from 'site-react/components/page';
import { Heading, Paragraph } from 'site-react/components/typography';
import { VerticalSpacing } from 'site-react/components/utility';
import Analytics from 'site-react/helpers/Analytics';

import meta from '../content/meta/inbox-has-moved';
import PageLayout from '../layouts/PageLayout';

export default function InboxHasMovedPage() {
  useEffect(() => {
    Analytics.setPageProperties({
      page_category: 'static',
      page_type: 'inbox-has-moved',
    });

    Analytics.pageTrack('Inbox has moved');
  }, []);

  return (
    <PageLayout meta={meta}>
      <Section verticalPadding="xxl">
        <Heading level="1" type="hero2">
          Inbox Has Moved
        </Heading>
        <VerticalSpacing size="lg" />
        <Paragraph isMarginless type="content1">
          Please use the links below to access your messages.
        </Paragraph>
        <VerticalSpacing size="lg" />
        <ul>
          <li>
            <Link href="/host/listings/viewings">Host inbox</Link>
          </li>
          <li>
            <Link href="/dashboard/hq/viewings">Guest inbox</Link>
          </li>
        </ul>
      </Section>
      <Curve
        bottomColor="neutral-900"
        height="lg"
        topColor="white"
        type="wave"
      />
    </PageLayout>
  );
}
